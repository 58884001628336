var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('a',{staticClass:"heading-title-1"},[_vm._v(_vm._s(_vm.item.name))]),_c('div',{staticClass:"card light mn"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"v-stack gap-3"},[_c('div',{staticClass:"pane-horizontal gap-3"},[_c('label',{staticClass:"text-right"},[_vm._v("Category:")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.item.category.name || "Not Set")+" ")])]),_c('div',{staticClass:"pane-horizontal gap-3"},[_c('label',{staticClass:"text-right"},[_vm._v("URL:")]),_c('a',{staticClass:"text-left",attrs:{"href":"#"},on:{"click":function($event){return _vm.openLink(_vm.item.url)}}},[_vm._v(_vm._s(_vm.item.url ? "Open in new tab" : "Empty"))])]),_c('div',{staticClass:"pane-horizontal gap-3"},[_c('label',{staticClass:"text-right"},[_vm._v("Rating:")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.item.rating || "Not Set")+" ")])]),_c('div',{staticClass:"pane-horizontal gap-3"},[_c('label',{staticClass:"text-right"},[_vm._v("Description:")]),_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.item.description || "Not Set")+" ")])])])])]),_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.$router.push('/lifeschool/item/edit/' + _vm.item._id)}}},[_vm._v(" Edit ")])]),_c('a',{staticClass:"heading-title-1"},[_vm._v("Comments")]),_c('Table',{attrs:{"items":_vm.item.comments,"defaultColumn":0,"css":'140px 160px 1fr',"columns":[
      {
        name: 'Date',
        sort: 'date',
        formatType: 'date',
        format: 'DD.MM.YYYY HH:mm',
        path: ['timestamp'],
      },
      {
        name: 'User',
        sort: 'alphabet',
        path: ['user', 'fullName'],
      },
      {
        name: 'Message',
        sort: 'alphabet',
        path: ['message'],
      } ]}}),_c('div',{staticClass:"v-stack h-stretch pa-3"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"comment",domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})]),_c('div',{staticClass:"h-stack h-end"},[_c('button',{staticClass:"submit",on:{"click":function($event){return _vm.sendComment()}}},[_vm._v("Send")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }