<template>
  <div class="v-stack h-stretch gap-3" v-if="item">
    <a class="heading-title-1">{{ item.name }}</a>
    <div class="card light mn">
      <div class="content">
        <div class="v-stack gap-3">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Category:</label>
            <div class="text-left">
              {{ item.category.name || "Not Set" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">URL:</label>
            <a class="text-left" href="#" @click="openLink(item.url)">{{
              item.url ? "Open in new tab" : "Empty"
            }}</a>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Rating:</label>
            <div class="text-left">
              {{ item.rating || "Not Set" }}
            </div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Description:</label>
            <div class="text-left">
              {{ item.description || "Not Set" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-stack h-end">
      <button
        @click="$router.push('/lifeschool/item/edit/' + item._id)"
        class="edit"
      >
        Edit
      </button>
    </div>
    <a class="heading-title-1">Comments</a>
    <Table
      :items="item.comments"
      :defaultColumn="0"
      :css="'140px 160px 1fr'"
      :columns="[
        {
          name: 'Date',
          sort: 'date',
          formatType: 'date',
          format: 'DD.MM.YYYY HH:mm',
          path: ['timestamp'],
        },
        {
          name: 'User',
          sort: 'alphabet',
          path: ['user', 'fullName'],
        },
        {
          name: 'Message',
          sort: 'alphabet',
          path: ['message'],
        },
      ]"
    >
    </Table>
    <div class="v-stack h-stretch pa-3">
      <textarea class="comment" v-model="message"></textarea>
    </div>
    <div class="h-stack h-end">
      <button class="submit" @click="sendComment()">Send</button>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      item: null,
      message: "",
    };
  },
  components: {
    Table,
  },
  methods: {
    ...mapActions(["getLifeSchoolItem", "addLifeSchoolComment"]),
    openLink(link) {
      let url = link;

      if (!url) {
        return;
      }

      if (!url.startsWith("http")) {
        url = "https://" + url;
      }

      window.open(url, "_blank");
    },
    sendComment() {
      if (this.item) {
        this.addLifeSchoolComment({
          message: this.message,
          item: this.item._id,
        })
          .then(() => {
            this.refresh();
            this.message = "";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    refresh() {
      this.getLifeSchoolItem(this.id)
        .then((item) => {
          this.item = item;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
